<template>
	<a-typography-title :level="5">
		<slot></slot>
	</a-typography-title>
	<hr style="margin-bottom: 20px" />
</template>

<script>
export default {};
</script>

<style></style>
